<template>
  <div class="mt-10 sm:mt-0">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Customers</h3>
          <p class="mt-1 text-sm text-gray-600">
            The customers and prices for {{ this.productName }}
          </p>
        </div>
      </div>

      <div class="mt-5 md:mt-0 md:col-span-2">
        <div class="mb-6 flex justify-between items-center">
          <div class="flex items-center w-full max-w-xs mr-4">
            <div class="flex w-full bg-white shadow rounded">
              <input
                v-on:keyup="search"
                v-model="keyword"
                autocomplete="off"
                type="text"
                name="search"
                placeholder="Search…"
                class="relative w-full px-6 py-2 rounded border placeholder-gray-500 text-gray-900 focus:outline-none focus:border-white focus:ring focus:z-10 sm:text-sm"
              />
            </div>
            <button
              @click="reset"
              type="button"
              class="ml-3 text-sm text-gray-500 hover:text-gray-700 focus:text-indigo-500"
            >
              Reset
            </button>
          </div>
          <button
            @click="openModalCreate()"
            type="button"
            class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add
          </button>
        </div>
        <div class="-my-2 overflow-x-auto">
          <div class="py-2 align-middle inline-block min-w-full">
            <div
              class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Customer
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    @click="openModalEdit(item.id)"
                    class="hover:bg-gray-50 cursor-pointer"
                    v-for="item in customersProducts.data"
                    :key="item.id"
                  >
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="ml-4">
                          <div class="text-sm text-gray-900">
                            {{ item.customer.name }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{ currency(item.price) }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="customersProducts.data" class="flex justify-end">
    <pagination
      :links="customersProducts.meta.links"
      :active-page="this.$route.name"
      :search-keyword="this.$route.query.search"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination.vue";
import { mapState } from "vuex";

export default {
  name: "CustomerProductByProduct",
  props: ["productName"],
  components: {
    Pagination,
  },
  data() {
    return {
      keyword: "",
      timer: null,
    };
  },
  computed: {
    ...mapState("customersProducts", {
      customersProducts: (state) => state.customersProducts,
      modalCustomersProducts: (state) => state.modalCustomersProducts,
    }),
    currency() {
      var formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });

      return (price) => formatter.format(price);
    },
  },
  mounted() {
    this.getCustomersProducts();
  },
  methods: {
    getCustomersProducts() {
      this.$store.dispatch("customersProducts/getCustomersProducts", {
        page: this.$route.query.page,
        search: this.$route.query.search,
        by: "product_id",
        id: this.$route.params.id,
      });
    },
    openModalCreate() {
      this.$store.dispatch("customersProducts/openModalCreate", true);
    },
    openModalEdit(id) {
      this.$store.dispatch("customersProducts/openModalEdit", true);
      this.$store.dispatch("customersProducts/editCustomersProducts", id);
    },
    search() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          page: 1,
          search: this.keyword,
        }),
      });

      // Search after user stop typing
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }

      this.timer = setTimeout(() => {
        this.$store.dispatch("customersProducts/getCustomersProducts", {
          page: 1,
          search: this.keyword,
          by: "product_id",
          id: this.$route.params.id,
        });
      }, 2000);
    },
    reset() {
      this.keyword = "";
      this.$store.dispatch("customersProducts/getCustomersProducts", {
        page: 1,
        search: "",
        by: "product_id",
        id: this.$route.params.id,
      });
      this.$router.push({
        name: "EditProduct",
        params: { id: this.$route.params.id },
      });
    },
  },
  watch: {
    $route(to, from) {
      // Watch only the ?page query string
      if (to.query.page !== from.query.page) {
        this.getCustomersProducts();
      }
    },
  },
};
</script>
